import nanostyled from 'nanostyled';

export const Center = nanostyled('div', {
  base: 'mx-auto  max-w-8xl',
});

export const Container = nanostyled('section', {
  base: 'w-full flex flex-col md:flex-row md:items-end',
});

export const StyledButton = nanostyled('button', {
  base:
    'text-sm sm:text-base font-extrabold tracking-wide uppercase mx-4 mb-4 pb-1',
  active: '',
});

export const Left = nanostyled('div', {
  base: 'md:w-2/3 mx-0 xs:mx-6 sm:mx-16 md:mx-16 flex flex-wrap',
});

export const Right = nanostyled('div', {
  base: 'md:w-1/3 mx-4 xs:mx-10 sm:mx-20 md:ml-0 md:mr-20',
});

export const SearchInput = nanostyled('input', {
  base:
    'bg-transparent text-xl w-full pl-8 pr-6 pt-2 pb-1 border-transparent border-2 border-solid focus:border-black outline-none',
});

export const Search = nanostyled('div', {
  base: 'flex items-center relative mb-2',
});
