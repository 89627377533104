import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import JournalFilter from 'components/journal-filter';

const JournalFilterContainer = ({ items }) => (
  <Consumer>
    {({
      filterJournal,
      searchJournal,
      changeSearchJournal,
      changeFilterJournal,
    }) => (
      <JournalFilter
        items={items}
        filterJournal={filterJournal}
        changeFilterJournal={changeFilterJournal}
        searchJournal={searchJournal}
        changeSearchJournal={changeSearchJournal}
      />
    )}
  </Consumer>
);

JournalFilterContainer.propTypes = {
  items: PropTypes.array.isRequired,
};

export default JournalFilterContainer;
