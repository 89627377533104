import nanostyled from 'nanostyled';

export const Container = nanostyled('div', {
  base:
    'w-full max-w-8xl flex flex-col md:flex-row md:items-end mb-12 md:mb-24',
  margin: 'mx-auto',
});

export const Left = nanostyled('div', {
  base: 'md:w-2/3 mx-4 xs:mx-10 sm:mx-20 md:mx-20 mt-32 xs:mt-48',
});

export const Right = nanostyled('div', {
  base: 'md:w-1/3 mx-4 xs:mx-10 sm:mx-20 md:ml-0 md:mr-20',
});

export const StyledSvg = nanostyled('svg', {
  base: 'w-full max-w-3xl md:offset-logo mb-2 md:mb-0',
});

export const Description = nanostyled('p', {
  base:
    'font-micro font-light text-base xs:text-lg lg:text-xl leading-normal tracking-tight mb-8 md:mb-0',
});
